<template>
<div class="row-space-tbf" v-if="loaded">
	<div class="space-left"><div v-if="$resize && $mq.above(780)" class="go-back" @click="$route.query.from_salary ? $router.push({ name: 'suspensions' }) : $router.push({ name: 'contract-show', params: {id: $route.params.contract_id}, query: {tab: 'suspension'} })"><icon-arrow-back /></div></div>
	<div class="content opacity-page">
			<div class="header-form">
				<h1 class="title">{{ $route.params.id ? $t('salary.suspension.title_edit') : $t('salary.suspension.title_new')}}</h1>
				<div class="actions">
					<button class="btn-tbf white only-icon" @click="$route.query.from_salary ? $router.push({ name: 'suspensions' }) : $router.push({ name: 'contract-show', params: {id: $route.params.contract_id}, query: {tab: 'suspension'} })"><icon-close class="icon-close" /></button>
				</div>
			</div>
			<show-contract v-if="userData" :data="userData" />
			<choose-contract-multiple v-if="!$route.params.id && !$route.params.contract_id" :contract="suspension" :validator="$v.suspension"/>
			<div class="form-group">
				<div class="data-group">
					<div class="title">{{ $t('salary.suspension.title') }}</div>
				</div>
				<div class="form">
					<div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
						<div class="label-header">
							<label class="label">{{ $t('salary.suspension.normative_act') }}*</label>
							<div v-if="$v.suspension.selectedNormativeAct.$error" class="error-msg">{{ $t('validator.required') }}</div>
						</div>
						<div class="input-box" v-bind:class="{has_error: $v.suspension.selectedNormativeAct.$error}">
							<div class="icon-left"><icon-contract /></div>
							<div class="icon-right" v-if="suspension.selectedNormativeAct != ''" @click.stop="suspension.selectedNormativeAct = '', suspension.name = ''"><icon-close class="icon-clear" /></div>
							<multiselect 
								v-model="suspension.selectedNormativeAct"
								v-bind:class="{populate: suspension.selectedNormativeAct != ''}"
								class="select-tags-tbf"
								:options="normativeActOptions"
								:allow-empty="true"
								:show-labels="false"
								track-by="id" 
								label="name"
								>
								<template slot="placeholder" slot-scope="props">
									<span class="text">
										{{ $t('salary.suspension.normative_act-ph') }}
									</span>
								</template>
								<template slot="noResult">{{ $t('create-user.no-results') }}</template>
								<template slot="noOptions">{{ $t('create-user.empty-list') }}</template>
							</multiselect>
						</div>
					</div>
					<div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
						<div class="label-header">
							<label class="label">{{ $t('salary.suspension.decision_number') }}</label>
						</div>
						<div class="input-box">
							<div class="icon-left"><icon-contract /></div>
							<input type="text" class="input-text" :placeholder="$t('salary.suspension.decision_number-ph')" v-model="suspension.decision_number">
						</div>
					</div>
					<div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
						<div class="label-header">
							<label class="label">{{ $t('salary.suspension.decision_date') }}</label>
							<div v-if="$v.suspension.decision_date.$error" class="error-msg">{{ $t('validator.required') }}</div>
						</div>
						<div class="input-box"  v-bind:class="{has_error: $v.suspension.decision_date.$error}">
							<div class="icon-left"><icon-calendar /></div>
							<div class="icon-right" v-if="Object.keys(suspension.decision_date).length" @click.stop="suspension.decision_date = {}"><icon-close class="icon-clear" /></div>
							<FunctionalCalendar
								ref="CalendarDateOfEmployment"
								v-model="suspension.decision_date"
								class="calendar-tbf"
								:configs="calendarConfigs"
							>
							<template v-slot:datePickerInput="props">
								<input
								class="vfc-single-input custom-input-picker"
								type="text"
								:value="$options.filters.moment(props.selectedDate, 'DD MMM YYYY')" 
								v-if="Object.keys(suspension.decision_date).length"/>
								<div class="vfc-single-input custom-placeholder-picker" v-else>{{ $t('salary.suspension.decision_date-ph') }}</div>
							</template>
							</FunctionalCalendar>
						</div>
					</div>
					<div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
						<div class="label-header">
							<label class="label">{{ $t('salary.suspension.start_date') }}*</label>
							<div v-if="$v.suspension.start_date.$error" class="error-msg">{{ $t('validator.required') }}</div>
						</div>
						<div class="input-box" v-bind:class="{has_error: $v.suspension.start_date.$error}">
							<div class="icon-left"><icon-calendar /></div>
							<div class="icon-right" v-if="Object.keys(suspension.start_date).length" @click.stop="suspension.start_date = {}"><icon-close class="icon-clear" /></div>
							<FunctionalCalendar
								ref="CalendarDateOfEmployment"
								v-model="suspension.start_date"
								class="calendar-tbf"
								:configs="calendarConfigs"
								@input="changeEndDate()"
							>
							<template v-slot:datePickerInput="props">
								<input
								class="vfc-single-input custom-input-picker"
								type="text"
								:value="$options.filters.moment(props.selectedDate, 'DD MMM YYYY')" 
								v-if="Object.keys(suspension.start_date).length"/>
								<div class="vfc-single-input custom-placeholder-picker" v-else>{{ $t('salary.suspension.start_date-ph') }}</div>
							</template>
							</FunctionalCalendar>
						</div>
					</div>
					<div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
						<div class="label-header">
							<label class="label">{{ $t('salary.suspension.final_date') }}</label>
						</div>
						<div class="input-box">
							<div class="icon-left"><icon-calendar /></div>
							<div class="icon-right"  v-if="Object.keys(suspension.final_date).length" @click.stop="suspension.final_date = {}"><icon-close class="icon-clear" /></div>
							<FunctionalCalendar
								ref="CalendarDateOfEmployment"
								v-model="suspension.final_date"
								class="calendar-tbf"
								:configs="calendarConfigsEndData"
							>
							<template v-slot:datePickerInput="props">
								<input
								class="vfc-single-input custom-input-picker"
								type="text"
								:value="$options.filters.moment(props.selectedDate, 'DD MMM YYYY')" 
								v-if="Object.keys(suspension.final_date).length"/>
								<div class="vfc-single-input custom-placeholder-picker" v-else>{{ $t('salary.suspension.final_date-ph') }}</div>
							</template>
							</FunctionalCalendar>
						</div>
					</div>
					<div class="input-group w-100">
						<div class="label-header">
							<label class="label">{{ $t('salary.vacation.observations') }}</label>
						</div>
						<div class="input-box bg-white">
							<textarea rows="3" :placeholder="$t('salary.vacation.observations-ph')" v-model="suspension.observations"></textarea>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="space-right"></div>
		<div class="form-submit crud">
			<div class="error-msg" v-show="error_message != ''">{{error_message}}</div>
			<button class="btn-tbf grey" @click="$route.query.from_salary ? $router.push({ name: 'suspensions' }) : $router.push({ name: 'contract-show', params: {id: $route.params.contract_id}, query: {tab: 'suspension'} })"><span class="text">{{ $t('general.cancel') }}</span></button>
			<button id="buttonCreate" class="btn-tbf blue" @click="saveAction('create')" v-if="!$route.params.id">
				<div class="loader"></div>
				<span class="text">{{ $t('general.add') }}</span>
			</button>
			<button id="buttonUpdate" class="btn-tbf blue" @click="saveAction('update')" v-else>
				<div class="loader"></div>
				<span class="text">{{ $t('general.update') }}</span>
			</button>
		</div>
	</div>
	<loader-suspensions-create v-else/>
</template>

<script>
	import ShowContract from  './ShowContract'
	import ChooseContractMultiple from  './ChooseContractMultiple'
	import IconContract from '../../../../Icons/Contract'
	import IconCalendar from '../../../../Icons/Calendar'
	import IconClose from '../../../../Icons/Close'
	import IconArrowBack from '../../../../Icons/ArrowLong'
	import LoaderSuspensionsCreate from '../../../../PagesLoaders/UsersCreate'
    import { required, requiredIf } from 'vuelidate/lib/validators'

	export default {
		components: {
			ShowContract,
			ChooseContractMultiple,
			IconContract,
			IconCalendar,
			IconClose,
			IconArrowBack,
			LoaderSuspensionsCreate
		},
		data() {
			return {
				userData: {},
				loaded: false,
				calendarConfigs: {
					dateFormat: 'yyyy-mm-dd',
					isDatePicker: true,
					isDateRange: false,
					isModal: true,
					isAutoCloseable: true,
					changeMonthFunction: true,
					changeYearFunction: true
				},
				calendarConfigsEndData: {
					dateFormat: 'yyyy-mm-dd',
					isDatePicker: true,
					isDateRange: false,
					isModal: true,
					isAutoCloseable: true,
					limits: {min: '', max : ''}
				},
				error_message: '',
                normativeActOptions: [],
                suspension: {
					contractSelected: '',
					selectedNormativeAct: '',
                    decision_number: '',
                    decision_date: {},
					start_date: {},
					final_date: {},
					observations: ''
                }
			}
		},
        validations: {
            suspension: {
				contractSelected: {
                    required: requiredIf(function () {
                        return !this.$route.params.id && !this.$route.params.contract_id
                    })
			    },
                selectedNormativeAct: { required },
				decision_date: { required },
				start_date: { required },
            }
        },
		async mounted(){
			this.userData = localStorage.userData ? JSON.parse(localStorage.userData) : '';
            await this.getSuspensionNormativeActs();

			if(this.$route.params.id){
				await this.getSuspensionData()
			}
		},
        methods: {
            async getSuspensionNormativeActs() {
                await axios.get('/contract-suspension-normative-acts').then(({data}) => {
                   this.normativeActOptions = data
                }).catch(error => {
					if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				}).finally(() => {
					setTimeout(() => {
						this.loaded = true
						setTimeout(() => {
							$('.opacity-page').addClass('show')
						}, 0)
					}, 0)
				})
            },

			async getSuspensionData() {
				await axios.get(`/contract-suspensions/${this.$route.params.id}`).then(({data}) => {
					this.suspension.selectedNormativeAct = this.normativeActOptions.find(el => el.id == data.data.normative_act_id)
					this.suspension.decision_number = data.data.decision_number
                    if(data.data.decision_date){
						this.suspension.decision_date = {
							selectedDate: data.data.decision_date,
							multipleDateRange: [],
							selectedDates: [],
							selectedDateTime: false,
							selectedDatesItem: "",
							selectedHour: "00",
							selectedMinute: "00",
							dateRange: {end: '',start: ''}
						}
					}
                    if(data.data.active_from){
						this.suspension.start_date = {
							selectedDate: data.data.active_from,
							multipleDateRange: [],
							selectedDates: [],
							selectedDateTime: false,
							selectedDatesItem: "",
							selectedHour: "00",
							selectedMinute: "00",
							dateRange: {end: '',start: ''}
						}
					}
                    if(data.data.active_to){
						this.suspension.final_date = {
							selectedDate: data.data.active_to,
							multipleDateRange: [],
							selectedDates: [],
							selectedDateTime: false,
							selectedDatesItem: "",
							selectedHour: "00",
							selectedMinute: "00",
							dateRange: {end: '',start: ''}
						}
					}
					this.suspension.observations = data.data.observations
                }).catch(error => {
					if(error.response){
						if(error.response.status == 403) {
							this.$router.push({name: 'forbbiden'})
						}else if(error.response.status == 404) {
							this.$router.push({name: 'not-found'})
						} else if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
			},
			changeEndDate() {
				if(this.suspension.start_date.selectedDate > this.suspension.final_date.selectedDate) {
					this.suspension.final_date = {}
				}
				this.calendarConfigsEndData.limits.min = this.suspension.start_date.selectedDate
			},
			saveAction(type) {
				var buttonName = `button${ type == 'create' ? 'Create' : 'Update'}`
				var btnSubmit = document.getElementById(buttonName);
				var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
				var btnSubmitText = document.querySelector(`#${buttonName} .text`)
				btnSubmit.disabled = true

				btnSubmit.classList.add('loading')
				btnSubmitLoader.classList.add('onProgress')
				btnSubmitText.innerHTML = this.$t('btn-submit.loading')
				this.error_message = '';

				this.$v.$touch()
                if(!this.$v.$invalid){
					let objData = {}

					objData.decision_date = Object.keys(this.suspension.decision_date).length ? this.suspension.decision_date.selectedDate : ''
					objData.active_from = Object.keys(this.suspension.start_date).length ? this.suspension.start_date.selectedDate : ''
					objData.active_to = Object.keys(this.suspension.final_date).length ? this.suspension.final_date.selectedDate : ''
					objData.normative_act_id = this.suspension.selectedNormativeAct ? this.suspension.selectedNormativeAct.id : ''
					objData.decision_number = this.suspension.decision_number
					objData.observations = this.suspension.observations
					
					if(this.$route.params.contract_id) {
						if(type == 'create'){
							this.createSuspension(type, objData, btnSubmit, btnSubmitText, btnSubmitLoader)
						}else if(type == 'update'){
							this.updateSuspension(type, objData, btnSubmit, btnSubmitText, btnSubmitLoader)
						}
					} else {
						var selectedContracts = this.suspension.contractSelected.length ? this.suspension.contractSelected.map(function(a) {return a.id;}) : ''
						if(selectedContracts.length) { objData.contract_ids = selectedContracts }
						this.createSalarySuspension(type, objData, btnSubmit, btnSubmitText, btnSubmitLoader)
					}
				}else{
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = type == 'create' ? this.$t('general.add') : this.$t('general.update')
							btnSubmit.disabled = false
						}, 1000)
					}, 300)
				}
			},
			createSalarySuspension(type, objData, btnSubmit, btnSubmitText, btnSubmitLoader){
				axios.post(`${this.$auth.user().instance.id}/contract-suspensions/store/multiple`, objData)
				.then(({data}) => {
                    btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.success')
						btnSubmit.classList.add('completed')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(() => {
							this.$router.push({ name: 'suspensions' })
						}, 1000);
					}, 300)
				}).catch(error => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = type == 'create' ? this.$t('general.add') : this.$t('general.update')
							btnSubmit.disabled = false
							if(error.response.status == 500){
								this.error_message = this.$t('error.500')
							}
							if(error.response.data.errors.popup) {
								this.$root.$emit('open_modal', 'error', {data: error.response.data.errors.popup[0]}, false);
							}
						}, 1000)
					}, 300)
				})
			},
			createSuspension(type, objData, btnSubmit, btnSubmitText, btnSubmitLoader){
				axios.post(`${this.$route.params.contract_id}/contract-suspensions/store`, objData)
				.then(({data}) => {
                    btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.success')
						btnSubmit.classList.add('completed')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(() => {
							this.$route.query.from_salary ? this.$router.push({ name: 'suspensions' }) : this.$router.push({ name: 'contract-show', params: {id: this.$route.params.contract_id}, query: {tab: 'suspension'} })
						}, 1000);
					}, 300)
				}).catch(error => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = type == 'create' ? this.$t('general.add') : this.$t('general.update')
							btnSubmit.disabled = false
							if(error.response.status == 500){
								this.error_message = this.$t('error.500')
							}
							this.error_message = Object.values(error.response.data.errors).join('')
							if(error.response.data.errors.popup) {
								this.$root.$emit('open_modal', 'error', {data: error.response.data.errors.popup[0]}, false);
							}
						}, 1000)
					}, 300)
				})
			},
			updateSuspension(type, objData, btnSubmit, btnSubmitText, btnSubmitLoader) {
				axios.patch(`/contract-suspensions/${this.$route.params.id}`, objData)
				.then(({data}) => {
                    btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.success')
						btnSubmit.classList.add('completed')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(() => {
							this.$route.query.from_salary ? this.$router.push({ name: 'suspensions' }) : this.$router.push({ name: 'contract-show', params: {id: this.$route.params.contract_id}, query: {tab: 'suspension'} })
						}, 1000);
					}, 300)
				}).catch(error => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = type == 'create' ? this.$t('general.add') : this.$t('general.update')
							btnSubmit.disabled = false
							if(error.response.status == 500){
								this.error_message = this.$t('error.500')
							}
							this.error_message = Object.values(error.response.data.errors).join('')
							if(error.response.data.errors.popup) {
								this.$root.$emit('open_modal', 'error', {data: error.response.data.errors.popup[0]}, false);
							}
						}, 1000)
					}, 300)
				})
			}
        }
	}
</script>